import React from 'react';
import { Layout } from 'react-admin';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { StarboltState } from '../types';

import Menu from './Menu';
import { starboltTheme, starboltAdminTheme } from './themes';
import StarboltAppBar from './AppBar';

import packageConfig from '../../package.json';

const useLayoutStyle = makeStyles(
  theme => ({
    version : {
      position : 'fixed',
      bottom   : 0,
      right    : 0,
      padding  : theme.spacing(1),
      color    : '#777',
      fontSize : '10px'
    }
  })
);

const StarboltLayout = (props : any) => {
  const classes = useLayoutStyle();
  const theme = useSelector((state: StarboltState) => state.starbolt.theme === 'Admin' ? starboltAdminTheme : starboltTheme);
  return (
    <Layout {...props} menu={Menu} appBar={StarboltAppBar} theme={theme}>
      {props.children}
      <div className={classes.version}>
        v{packageConfig.version}
      </div>
    </Layout>
  );
};

export default StarboltLayout;