import React, { useState, FC} from 'react';
import { TextInput, Edit, SimpleForm, TabbedForm, FormTab, BooleanInput, EditProps } from 'react-admin';

import Schedule from './components/Schedule';


const SpaceEdit: FC<EditProps> = props => {
    return (
        <Edit {...props} >
            <TabbedForm redirect="list">
                <FormTab label='Informations'>
                    <TextInput source="name"/>
                    <TextInput source="description"/>
                    <TextInput source="code"/>
                    <TextInput source="address.formattedAddress"/>
                </FormTab>
                <FormTab label="Horaires">
                  <Schedule />
                </FormTab>
            </TabbedForm>
        </Edit>
    )
}

export default SpaceEdit;