import React from "react";
import styled from "styled-components";

interface Props {
  title: string;
  data: number | string;
  iconPath: any;
}

const CardContainer = styled.div`
  padding: 18px;
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0.2);
  text-align: center;
`;

const CardBody = styled.div`
  padding: 10px;
  span {
    float: left;
    clear: both;
  }
`;

const Icon = styled.img`
  width: max-content;
  max-width: 80px;
`;

const CardContent = styled.div`
  padding-left: 78px;

  p {
    line-height: 16px;
    font-size: 16px;
    margin-bottom: 8px;
    height: 16px;
    white-space: nowrap;
    overflow: hidden;
  }
`;

const Card = ({ iconPath, data, title }: Props) => {
  return (
    <CardContainer>
      <CardBody>
        <span>
          <Icon src={iconPath} alt="icon" />
        </span>
        <CardContent>
          <p>{title}</p>
          <p>{data}</p>
        </CardContent>
      </CardBody>
    </CardContainer>
  );
};

export default Card;

