import React, { useEffect, useState, useMemo } from 'react';
import { useDataProvider } from 'react-admin';
import { useSelector, useDispatch } from 'react-redux';
// import useRidesCount from '../../shared/hooks/useRideCount';
import { RowPadded, ColPadded, ChartContainer } from "./style";
import { labels, options } from "../../shared/components/ChartData";
import Ride from '../../shared/entities/Ride';
import Card from '../../shared/components/Card';
import { cardData } from "../../shared/components/CardData";
import StarboltChart from '../../shared/components/Chart';
import { Grid, Col, Row } from "react-flexbox-grid";
import { spaceSelected } from '../../store/actions/account';
import { StarboltState } from '../../types';


interface Props {
    rideList: Ride[];
    loading?: boolean;
    chartData: number[];
    userCount: number;
    currentMonth: number;
    firstMonth: number;
    activeUsers: number;
    rideDurationAverage: number;
  }

  
  const calcRideByMonths = (
    rideCount: number,
    currentMonth: number,
    firstMonth: number
  ): string =>
    `${Math.round((rideCount / (currentMonth + 1 - firstMonth)) * 100) / 100}`;
  
  const sortBy = (a: Ride, b: Ride) =>
    new Date(b.time.end).getTime() - new Date(a.time.end).getTime();

    function getTimeDifference(ride: Ride) {
      return (
        new Date(ride.time.end).getTime() - new Date(ride.time.start).getTime()
      );
    }


function Dashboard(props : any){
  const currentMonth = new Date().getMonth();
  
  const [firstMonth, setFirstMonth] = useState(0);
  const [rideList, setRideList]  = useState([]);
  const [activeUsers, setActiveUsers] : any  = useState([]);
  const [users, setUsers] : any  = useState([]);
  const [rideDurationAverage, setRideDurationAverage] = useState(0);
  const [rideByMonth, setRideByMonth] : any = useState(0);
  const [chartData, setChartData] : any = useState();
  const [space, setSpace] : any = useState('');

  const state = useSelector((state : StarboltState) => state.starbolt);
  const dispatch = useDispatch();
  const dataProvider = useDataProvider();
  
  useEffect(()=>{
    setSpace(state.selectedSpace);
    //load dasboard data
    if(space !== state.selectedSpace){
      dataProvider.getList('Ride', {
        pagination : { page : 1, perPage : 1000}, 
        sort : {field : '', order : ''}, 
        filter : { space : state.selectedSpace}})
        .then( ({data} : any) => { 
          setRideList(data);
          const activeIds = data.map(
            (ride : any) => ride.user && ride.user._id
          );
          const ids = new Set(activeIds);
          setActiveUsers([ids].length);
          let sum = 0;
          data.forEach((ride : any) => sum += getTimeDifference(ride));
          setRideDurationAverage(sum/data.length)
  
          let start: number | null = null;
  
          data.forEach((ride : any) => {
            if (!start || new Date(ride.time.end).getMonth() < (start || 0))
              start = new Date(ride.time.end).getMonth();
            });
  
          const firstMonth = !start ? 0 : start - 1;
          setRideByMonth(calcRideByMonths(data.length, currentMonth, firstMonth))  
        })
      dataProvider.getList('User', {
        pagination : { page : 1, perPage : 1000}, 
        sort : {field : '', order : ''}, 
        filter : { spaces : state.selectedSpace}})
        .then(({data} : any) =>{
          setUsers(data.length);
        })
    }
  }, [state])

  const barChartDataset = useMemo(() => {
    const months = Array.from(
      { length: currentMonth + 1 - firstMonth },
      () => 0
    );
    
    rideList.forEach((ride : any) => {
      months[new Date(ride?.time.end).getMonth() - firstMonth]++;
    });
    setChartData(months)
    return months;
  }, [rideList, currentMonth, firstMonth]);

  const cards = useMemo(
    () =>
      cardData({
        rideList,
        activeUsers,
        users,
        rideDurationAverage,
        rideByMonth
      }),
    [rideList, activeUsers, users, rideDurationAverage, rideByMonth]
  );


  return (
    <Grid>
      <Row>
        <h1>Dashboard</h1>
      </Row>
      <RowPadded middle="xs">
          {
          cards.map((card, index) => (
              <ColPadded key={index} xs={12} sm={12} md={6} lg={6} xl>
                  <Card {...card} />
              </ColPadded>
          ))
          }
      </RowPadded>
      <RowPadded  middle="xs">
        <Col xs>
          <ChartContainer>
            <StarboltChart
              labels={labels.slice(firstMonth, currentMonth + 1)}
              datasets={[
                {
                  fill: false,
                  data: barChartDataset,
                  label: "Rides",
                  backgroundColor: "#272744",
                  borderColor: "#ffe604",
                },
              ]}
              options={options}
              
            />
          </ChartContainer>
        </Col>
      </RowPadded>
    </Grid>
  )
}

export default Dashboard;
